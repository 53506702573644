import React, { ReactElement, FC } from "react"
import { Row, Col, Carousel, Typography, Button } from "antd"
import { StaticImage } from "gatsby-plugin-image"

import Link from "../link/link"
import { slugify } from "../../global/utils/string"
import { LinkTarget } from "../../global/enums"

import { I_Button, I_Post } from "../../global/interfaces"

const { Title, Paragraph } = Typography

import "./hero-blog.less"

enum Country {
  EN = "en",
  ES = "es",
  IT = "it",
}

enum BaseUrl {
  EN = "/blog",
  ES = "/es/blog",
  IT = "/it/blog",
}

interface I_Props {
  carouselPosts: I_Post[]
  featuredPosts: I_Post[]
  readMore: I_Button
  locale: string
}

const TopPostsComponent: FC<I_Props> = ({
  carouselPosts,
  featuredPosts,
  readMore,
  locale,
}: I_Props): ReactElement => {
  const [country] = locale.split("-")
  const baseUrl =
    country === Country.EN
      ? BaseUrl.EN
      : country === Country.ES
      ? BaseUrl.ES
      : BaseUrl.IT

  return (
    <section className="hero-blog">
      <Row className="hero-blog__wrapper">
        <Col span={24}>
          <Row gutter={[20, 20]}>
            <Col xs={24} lg={14}>
              <Carousel effect="fade">
                {carouselPosts.map(({ id, title, intro, cover }: I_Post) => (
                  <div className="hero-blog__carousel-item" key={id}>
                    <div className="hero-blog__carousel-content">
                      <Title level={2} className="hero-blog__carousel-title">
                        {title}
                      </Title>
                      <Paragraph className="hero-blog__carousel-text">
                        {intro}
                      </Paragraph>
                      <Link
                        link={`${baseUrl}/${slugify(title)}`}
                        target={LinkTarget.Self}
                      >
                        <Button type="primary">{readMore.text}</Button>
                      </Link>
                    </div>
                    <img
                      src={cover.url}
                      alt={intro}
                      width="100%"
                      className="hero-blog__carousel-image"
                    />
                  </div>
                ))}
              </Carousel>
            </Col>
            <Col xs={24} lg={10}>
              <Row gutter={[20, 20]}>
                {featuredPosts.map(({ id, title, intro, cover }: I_Post) => (
                  <Col key={id} span={24} className="hero-blog__featured-item">
                    <div className="hero-blog__featured-image">
                      <img src={cover.url} alt={intro} height="100%" />
                    </div>
                    <div className="hero-blog__featured-content">
                      <Title level={3} className="hero-blog__featured-title">
                        {title}
                      </Title>
                      <Paragraph
                        ellipsis={{ rows: 2, symbol: "..." }}
                        className="hero-blog__featured-text"
                      >
                        {intro}
                      </Paragraph>
                      <Link
                        link={`${windowLocationPathname}/${slugify(title)}`}
                        target={LinkTarget.Self}
                        className="hero-blog__featured-link"
                      >
                        {readMore.text}
                      </Link>
                    </div>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <StaticImage src="../../assets/curves_01.png" alt="" />
    </section>
  )
}

export default TopPostsComponent
