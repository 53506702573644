import React, { ReactElement, FC, useState, useEffect } from "react"
import { Card, Form, Input, Button, Select, notification } from "antd"
import ReactMarkdown from "react-markdown"
import useSWR from "swr"

import SocialNetworkLink from "../social-network-link/social-network-link"
import {
  I_Input,
  I_Button,
  I_Select,
  I_Option,
  I_SocialNetworks,
  I_SocialNetwork,
  I_Notification,
} from "../../global/interfaces"
import { requiredRule, nameValidator, typeRule } from "../../global/utils/form"
import { TypeRule, NotificationPlacement } from "../../global/enums"
import { postJsonData } from "../../services/api"

import "./subscribe-form.less"

const { Option } = Select
const { useForm, Item } = Form

interface I_Props {
  title: string
  text: string
  nameInput: I_Input
  emailInput: I_Input
  button: I_Button
  country: I_Select
  socialNetwork: I_SocialNetworks
  successNotification: I_Notification
  errorNotification: I_Notification
}

const SubscribeFormComponent: FC<I_Props> = ({
  title,
  text,
  nameInput,
  emailInput,
  button,
  country,
  socialNetwork,
  successNotification,
  errorNotification,
}: I_Props): ReactElement => {
  const [postJsonDataParams, setPostJsonDataParams] = useState<any>(null)
  const [processingSubscription, setProcessingSubscription] = useState(false)
  const { data, error } = useSWR(postJsonDataParams, postJsonData)
  const [form] = useForm()
  const initialValues = {
    name: "",
    email: "",
    country: country.options[0].value,
  }

  useEffect(() => {
    if (data || error) {
      setProcessingSubscription(false)
      setPostJsonDataParams(null)
      form.resetFields()
      if (data) {
        if (data.error) {
          notification.error({
            ...errorNotification,
            placement: NotificationPlacement.BottomRight,
          })
        } else {
          notification.success({
            ...successNotification,
            placement: NotificationPlacement.BottomRight,
          })
        }
      } else {
        notification.error({
          ...errorNotification,
          placement: NotificationPlacement.BottomRight,
        })
      }
    }
  }, [data, error])

  const handleOnFinish = (formData: any) => {
    const url = `${process.env.GATSBY_STRAPI_API_URL}/subscribers`
    setProcessingSubscription(true)
    setPostJsonDataParams([url, formData])
  }

  return (
    <Card
      title={title}
      className="subscribe-form"
      extra={
        <div className="subscribe-form__icons">
          {socialNetwork.socialNetworks.map(
            ({ id, type, link }: I_SocialNetwork) => (
              <SocialNetworkLink key={id} id={id} type={type} link={link} />
            )
          )}
        </div>
      }
    >
      <ReactMarkdown children={text} />
      <Form form={form} initialValues={initialValues} onFinish={handleOnFinish}>
        <Item
          name="name"
          rules={[
            requiredRule(nameInput.error),
            { validator: (_, value) => nameValidator(value, nameInput.error) },
          ]}
        >
          <Input placeholder={nameInput.placeholder} />
        </Item>
        <Item
          name="email"
          rules={[
            requiredRule(emailInput.error),
            typeRule(emailInput.error, TypeRule.Email),
          ]}
        >
          <Input placeholder={emailInput.placeholder} />
        </Item>
        <Item name="country">
          <Select placeholder={country.label}>
            {country.options.map(({ id, text, value }: I_Option) => (
              <Option key={id} value={value}>
                {text}
              </Option>
            ))}
          </Select>
        </Item>
        <Button
          type="primary"
          htmlType="submit"
          className="subscribe-form__button"
          loading={processingSubscription}
        >
          {button.text}
        </Button>
      </Form>
    </Card>
  )
}

export default SubscribeFormComponent
