import React, { ReactElement, FC } from "react"
import { Row, Col, Typography } from "antd"
import ReactMarkdown from "react-markdown"

import Link from "../link/link"
import { I_Button, I_Post } from "../../global/interfaces"
import { LinkTarget } from "../../global/enums"
import { slugify } from "../../global/utils/string"

import "./posts.less"

const { Title, Paragraph } = Typography

interface I_Props {
  title: string
  subtitle: string
  posts: I_Post[]
  readMore: I_Button
  baseUrl: string
}

const PostsComponent: FC<I_Props> = ({
  title,
  subtitle,
  posts,
  readMore,
  baseUrl,
}: I_Props): ReactElement => {
  return (
    <section className="posts">
      <Title level={2} className="posts__title">
        {title}
      </Title>
      <ReactMarkdown children={subtitle} className="posts__text" />
      <Row gutter={[20, 20]}>
        {posts.map(({ id, title, intro, cover }: I_Post) => (
          <Col key={id} xs={24} md={12} className="posts__item">
            <div className="posts__item-image">
              <img src={cover.url} alt={intro} width="100%" />
            </div>
            <div className="posts__item-content">
              <Title level={3}>{title}</Title>
              <Paragraph ellipsis={{ rows: 2, symbol: "..." }}>
                {intro}
              </Paragraph>
              <Link
                link={`${baseUrl}/blog/${slugify(title)}`}
                target={LinkTarget.Self}
              >
                {readMore.text}
              </Link>
            </div>
          </Col>
        ))}
      </Row>
    </section>
  )
}

export default PostsComponent
