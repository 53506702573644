import React, { ReactElement, FC } from "react"
import { graphql } from "gatsby"
import { Row, Col } from "antd"

import Layout from "../components/layout/layout"
import Container from "../components/container/container"
import HeroBlog from "../components/hero-blog/hero-blog"
import SubscribeForm from "../components/subscribe-form/subscribe-form"
import Posts from "../components/posts/posts"
import SEO from "../components/seo/seo"
import CookiesBanner from "../components/cookies-banner/cookies-banner"
import CountryLanguageBanner from "../components/country-language-banner/country-language-banner"
import {
  I_CountryLanguage,
  I_Navigation,
  I_Post,
  I_Footer,
  I_Input,
  I_Button,
  I_Select,
  I_SocialNetworks,
  I_CookiesBanner,
  I_Notification,
  I_SEO,
  I_Location,
} from "../global/interfaces"

interface I_Strapi {
  countryLanguage: I_CountryLanguage
  navigation: I_Navigation
  posts: I_Post[]
  footer: I_Footer
  socialNetwork: I_SocialNetworks
  cookiesBanner: I_CookiesBanner
}

interface I_PageContext {
  featuredTitle: string
  featuredSubtitle: string
  postsTitle: string
  postsSubtitle: string
  followUs: string
  subscribeTitle: string
  subscribeText: string
  nameInput: I_Input
  emailInput: I_Input
  country: I_Select
  subscribeButton: I_Button
  topPosts: I_Post[]
  featuredPosts: I_Post[]
  readMore: I_Button
  successNotification: I_Notification
  errorNotification: I_Notification
  seo: I_SEO
  locale: string
  baseUrl: string
}

interface I_Props {
  pageContext: I_PageContext
  data: {
    strapi: I_Strapi
  }
  location: I_Location
}

const Blog: FC<I_Props> = ({
  pageContext,
  data,
  location,
}: I_Props): ReactElement => {
  const {
    countryLanguage,
    navigation,
    posts,
    footer,
    socialNetwork,
    cookiesBanner,
  } = data.strapi
  const {
    postsTitle,
    postsSubtitle,
    subscribeTitle,
    subscribeText,
    nameInput,
    emailInput,
    country,
    subscribeButton,
    topPosts,
    featuredPosts,
    readMore,
    successNotification,
    errorNotification,
    locale,
    baseUrl,
  } = pageContext

  const topPostIds = topPosts.map(({ id }) => id)
  const featuredPostIds = featuredPosts.map(({ id }) => id)
  const filteredPosts = [...topPostIds, ...featuredPostIds]

  const allPosts = posts.filter(({ id }) => !filteredPosts.includes(id))

  return (
    <>
      <SEO {...pageContext.seo} baseUrl={baseUrl} />
      <CookiesBanner {...cookiesBanner} />
      <CountryLanguageBanner {...countryLanguage} baseUrl={baseUrl} />
      <Layout
        navigation={navigation}
        footer={footer}
        location={location}
        baseUrl={baseUrl}
      >
        <HeroBlog
          carouselPosts={topPosts}
          featuredPosts={featuredPosts}
          readMore={readMore}
          locale={locale}
        />
        <Container>
          <Row gutter={40}>
            <Col xs={24} lg={8}>
              <SubscribeForm
                title={subscribeTitle}
                text={subscribeText}
                nameInput={nameInput}
                emailInput={emailInput}
                button={subscribeButton}
                country={country}
                socialNetwork={socialNetwork}
                successNotification={successNotification}
                errorNotification={errorNotification}
              />
            </Col>
            <Col xs={24} lg={16}>
              <Posts
                title={postsTitle}
                subtitle={postsSubtitle}
                posts={allPosts}
                readMore={readMore}
                baseUrl={baseUrl}
              />
            </Col>
          </Row>
        </Container>
      </Layout>
    </>
  )
}

export default Blog

export const pageQuery = graphql`
  query BlogPageQuery($locale: String) {
    strapi {
      countryLanguage(locale: $locale) {
        text
        countryOptions {
          label
          options {
            id
            text
            value
          }
        }
        languageOptions {
          label
          options {
            id
            text
            value
          }
        }
        button {
          text
        }
      }
      navigation(locale: $locale) {
        links {
          id
          link
          target
          text
        }
      }
      posts(locale: $locale) {
        id
        title
        intro
        cover {
          url
        }
        published_at
      }
      socialNetwork(locale: $locale) {
        title
        socialNetworks {
          id
          type
          link
        }
      }
      footer(locale: $locale) {
        copyright
        linkList {
          id
          title
          links {
            id
            text
            link
            target
          }
        }
      }
      cookiesBanner(locale: $locale) {
        title
        text
        acceptButton {
          text
        }
      }
    }
  }
`
